//ORDERBOOK
.KM_OrderBook {
    padding: 40px;
    max-height: 490px;
    overflow: hidden;

    .OrderBook_header {
        color: var(--dark-900, #232530);

        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 36px;
        /* 150% */
        margin-bottom: 20px;
    }

    .general-info-container {
        display: grid;
        grid-template-columns: repeat(3, minmax(160px, 1fr));
        grid-template-rows: repeat(2, auto);
        grid-gap: 20px;
        margin-bottom: 20px; // for gap between rows
        justify-content: space-between;

        .general-info-row {
            display: flex;
            flex-direction: column;
            gap: 6px;
            max-width: 160px;
            flex-basis: calc(33.33% - 20px); // for 3 items per row

            .general-info-header {
                color: var(--dark-700, #4F5059);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.48px;
                text-transform: uppercase;
            }

            .general-info-value {
                color: var(--dark-900, #232530);
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
}