/* Data grid overrides */

.mui-header {
    background: var(--table-header-background);
}

.MuiDataGrid-columnHeaderTitle {
    text-transform: uppercase;
    color: var(--dark-700, #4F5059);
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.MuiDataGrid-columnHeaders {
    border-color: var(--dark-200) !important;

}

//header hover
.MuiDataGrid-columnHeader:hover {
    .MuiDataGrid-columnHeaderTitle {
        color: var(--blue-900);
    }

    .MuiDataGrid-iconButtonContainer {

        .fa-caret-down,
        .fa-caret-up {
            color: var(--blue-900);
        }
    }
}

// sorted header
.MuiDataGrid-columnHeader--sortable:not(.MuiDataGrid-columnHeader--sorted) {
    .MuiDataGrid-iconButtonContainer {
        opacity: 0.5;
    }
}

.MuiDataGrid-columnHeader--sorted {
    .MuiDataGrid-columnHeaderTitle {
        color: var(--blue-900);
    }

    .MuiDataGrid-iconButtonContainer {

        .fa-caret-down,
        .fa-caret-up {
            color: var(--blue-900);
        }
    }
}

.MuiDataGrid-rowReorderCell .MuiSvgIcon-root {
    color: var(--dark-300);
    font-size: 14px;
}

.MuiDataGrid-root {
    border-width: 0px;
}

.mui-cell {
    border-bottom: 1px solid var(--border-color);
    border-top: 0;
    border-left: 0;
}

.watchTable-cell {
    width: 100%;
    height: 100%;
}

.newLineFlicker {
    animation: new-row-highlight 1s forwards;
    // animation-iteration-count: 1;
}