// READ ME:
// It is very important that we use "outline" instead of "border"
// Border adds width to the overall element while outline does not.
// This prevents all of the elements shifting around when focusing items.

.KCL_filter-item {
    background: var(--blue-100);
    color: var(--blue-600);
    font-weight: 600;
    border-radius: 6px;
    outline: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    margin: 2.5px;
    cursor: pointer;

    &:hover {
        background: var(--blue-200);
    }

    &:focus {
        outline: 2px solid var(--blue-200);
    }

    &.selected {
        background: var(--blue-200);

        &:hover {
            background: var(--blue-100);
        }

        &:focus {
            outline: 3px solid var(--blue-100);
        }
    }

    &.disabled {
        background: var(--blue-100);
        color: var(--blue-200);
        pointer-events: none;
        outline: none;
        
        .fa-circle, .fa-circle-check, .fa-check-circle {
            color: var(--blue-200);
        }
    }

    &.lg { 
        font-size: 14px;
        line-height: 25px;
        padding: 3px 10px 2px;
    }

    &.sm { 
        font-size: 11px;
        line-height: normal;
        padding: 4px 6px 3px 6px; 
    }

    .fa-circle, .fa-circle-check, .fa-check-circle {
        color: var(--blue-600);
        margin-right: 8px;
    }

    .lock_icon {
        font-size: 11px;
        margin-left: 5px;
        margin-bottom: 1px;
    }
}