header {
    width: 100%;
    display: flex;
    height: 40px;
    padding: 9px 20px;
    background: linear-gradient(96deg, #04182B 4.3%, #0C285F 37.13%, #29175B 76.87%);
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.25);
    align-items: center;
    justify-content: space-between;

    &.login_header {
        box-shadow: none;
        position: fixed;
        z-index: 3;
    }

    .logoSection {
        display: flex;
        align-items: flex-end;

        font-size: 0;
        line-height: 0;

        >.vertical-ruler {
            border-left: 1px solid rgba(255, 255, 255, 0.3); // 30% opacity
            height: 18px;
            margin: 0 8px;
        }

        .marketWrapper {
            font-size: 0;
            line-height: 0;

            >svg {
                width: auto;
                height: 16px;
            }
        }
    }

    .rightSection {
        display: flex;
        align-items: center;
        gap: 20px;

        .noHoverButton {
            &:hover {
                background-color: transparent;
            }
        }

        .vertical-ruler {
            border-left: 1px solid rgba(255, 255, 255, 0.4); // 40% opacity
            height: 20px;
        }
    }

    .beta_label {
        position: relative;
        margin-left: 5px;
        top: -24px;

        span {
            position: absolute;
            font-size: 10px;
            font-weight: 500;
            border-radius: 6px;
            padding: 2px 4px;
            line-height: 10px;
            color: #fff;
            background: #4569ee;
            overflow: hidden;
        }
    }

    .headerButton {
        color: white;
        display: flex;
        align-items: center;
        text-transform: none;

        &:hover,
        &.active {
            color: var(--secondary-sky, #6CD3FF);

            .fa-icon {
                path {
                    fill: var(--secondary-sky, #6CD3FF);
                }
            }
        }

        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }

    .rotate {
        transition: transform 0.3s ease-in-out;
    }

    .rotate-180 {
        transform: rotate(180deg);
    }
}

.settingsMenu {
    background-color: white;
    padding: 25px 40px 30px 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .menu-item {
        position: relative;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: normal;
        text-decoration: none;
        color: #1D3097;
        padding-bottom: 1px;

        .fa-icon {
            font-size: 14px;

            path {
                fill: #1D3097;
            }
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background-color: #0a58ca;
            transition: width 0.3s;
        }

        &:hover:after {
            width: 100%;
        }

        &:hover {
            .fa-icon>path {
                fill: #0a58ca;
            }

            color:#0a58ca;
        }
    }
}

//dashboard & workspace menu
.dwMenu {
    display: flex;
    padding: 25px 40px 30px 30px;
    gap: 40px;
    background-color: white;

    .small_header {
        color: var(--dark-600, #65666E);
        /* Tables / Small / Header */
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
    }

    .dashboard,
    .workspace {
        display: flex;
        flex-direction: column;
        gap: 20px;

        //the first child should only get 15px row gap between the first child and second
        > :first-child {
            margin-bottom: -5px;
        }

        div.wrapper {
            line-height: 10px;
            cursor: pointer;
            width: fit-content;
        }
    }

    .menu-item {
        position: relative;
        display: inline-flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        color: #1D3097;
        padding-bottom: 1px;
        margin-bottom: -4px;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 1px;
            background-color: #0a58ca;
            transition: width 0.3s;
        }

        &:hover:after {
            width: 100%;
        }

        &:hover {
            >div {
                color: #0a58ca;
            }

            .fa-icon>path {
                fill: #0a58ca;
            }
        }

        >div {

            color: var(--blue-900, #1D3097);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .fa-icon {
            font-size: 14px;

            path {
                fill: var(--blue-600, #2235DF);
            }
        }
    }

    .dwMenu_vr {
        border-left: 1px solid rgba(211, 211, 214);

    }
}