@import 'react-datepicker/dist/react-datepicker.css';

.KCL_datepicker {
    position: relative;
    width: fit-content;

    .react-datepicker__triangle {
        display: none;
    }

    &.xsm {
        max-width: 120px;
        font-size: 12px;
        line-height: 14px;

        .datepicker__label {
            top: 7px;
            left: 12px;

            &.is-on-top {
                font-size: 10px;
                line-height: 12px;
            }
        }

        .datepicker__icon {
            right: 10px;
            margin-top: -5px;
            width: 10px;
            height: 10px;
            font-size: 10px;
            line-height: 10px;
        }

        .datepicker__input {
            height: 20px;
            padding: 0 12px;
            font-size: 12px;
        }

        .react-datepicker__current-month {
            padding: 5px 0;
            text-transform: capitalize;
            font-size: 14px;
        }

        .react-datepicker__navigation {
            width: 12px;
            height: 12px;
            color: var(--black-900);
            top: 10px;
        }

        .react-datepicker__navigation--previous,
        .react-datepicker__navigation--next {
            font-size: 10px;
            width: 12px;
            height: 12px;
        }

        .react-datepicker__day {
            width: 20px;
            height: 20px;
            font-size: 12px;
            margin: 3px;
        }

        .react-datepicker__day-names {
            padding: 0px 5px;

            .react-datepicker__day-name {
                width: 20px;
                height: 20px;
                margin: 3px;
            }
        }
    }

    &.sm {
        max-width: 120px;
        font-size: 14px;
        line-height: 16px;

        .datepicker__label {
            top: 7px;
            left: 12px;

            &.is-on-top {
                font-size: 10px;
                line-height: 12px;
            }
        }

        .datepicker__icon {
            right: 10px;
            margin-top: -6px;
            width: 12px;
            height: 12px;
            font-size: 12px;
            line-height: 12px;
        }

        .datepicker__input {
            height: 30px;
            padding: 0 12px;
        }
    }

    &.lg {
        max-width: 160px;
        font-size: 16px;
        line-height: 19px;

        .datepicker__label {
            top: 13.5px;
            left: 15px;

            &.is-on-top {
                font-size: 11px;
                line-height: 13px;
            }
        }

        .datepicker__icon {
            right: 15px;
            margin-top: -8px;
            width: 16px;
            height: 16px;
            font-weight: 900;
            font-size: 14px;
            line-height: 14px;
        }

        .datepicker__input {
            height: 45px;
            padding: 0 15px;
        }

        @include breakpoint-down(mobile) {
            &:not(.responsive-size) {
                max-width: none;
            }

            &.responsive-size {
                max-width: 120px;
                font-size: 14px;
                line-height: 16px;

                .datepicker__label {
                    left: 12px;

                    &.is-on-top {
                        font-size: 10px;
                        line-height: 12px;
                    }
                }

                .datepicker__icon {
                    right: 10px;
                    margin-top: -6px;
                    width: 12px;
                    height: 12px;
                    font-size: 12px;
                    line-height: 12px;
                }

                .datepicker__input {
                    height: 30px;
                    padding: 0 12px;
                }
            }
        }
    }

    &.disabled {
        .datepicker__icon {
            color: var(--dark-300);
        }

        .datepicker__input {
            background-color: var(--dark-050);

            &:hover {
                cursor: default;
                border-color: var(--dark-300);
                box-shadow: 0px 4px 4px #0000000A;
            }
        }

        .datepicker__label {
            color: var(--dark-300);
            background-color: var(--dark-050);
        }
    }

    .datepicker__label {
        position: absolute;
        color: var(--dark-600);
        background: #fff;
        z-index: 1;
        pointer-events: none;

        @include vendor-prefix(transition, (top .25s ease-out,
                font-size .25s ease-out,
                line-height .25s ease-out,
                padding .25s ease-out));

        &.is-on-top {
            padding: 0 3px;
            top: -5px;
        }
    }

    .datepicker__icon {
        position: absolute;
        top: 50%;
        pointer-events: none;
    }

    .datepicker__input {
        appearance: none;
        color: #000;
        border: 1px solid var(--dark-300);
        border-radius: 6px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        padding: 0 10px;
        display: block;
        width: 100%;

        &:hover {
            border-color: var(--blue-600);
            box-shadow: 0px 4px 4px var(--hover-box-shadow-color);
            cursor: pointer;

            &.has-error {
                border-color: var(--red-600);
            }
        }

        &.max_width {
            max-width: unset;
        }
    }

    .react-datepicker-popper {
        z-index: 3;
    }

    .react-datepicker {
        font-size: 1rem;
        box-shadow: 0px 4px 4px rgba(34, 53, 223, 0.2);
        border-radius: 6px;
        border-color: var(--blue-600);
        background: #fff;

        .react-datepicker__triangle {
            display: none;
        }
    }

    .react-datepicker__header {
        background: var(--blue-100);
        padding: 0;
        border-radius: 6px 6px 0 0;
    }

    .react-datepicker__day-names {
        background: #fff;
        display: flex;
    }

    .react-datepicker__current-month {
        padding: 13px 0;
        text-transform: capitalize;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        font-size: 16px;
    }

    .react-datepicker__week {
        display: flex;
    }


    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 28px;
        height: 28px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 14px;
        border: 1px solid transparent;
        margin: 5px;
    }

    .react-datepicker__day-name {
        font-size: 11px;
        font-weight: 900;
        color: var(--table-header-text);
    }

    .react-datepicker__day--outside-month {
        opacity: 0.5;
    }

    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
        background: var(--blue-100);
        border-color: #D5DEFF;
        color: var(--black-900);
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
        background: var(--blue-600);
    }

    .react-datepicker__navigation {
        width: 26px;
        height: 26px;
        color: var(--black-900);
        top: 10px;
    }

    .react-datepicker__navigation--previous {
        left: 10px;
    }

    .react-datepicker__navigation--next {
        right: 10px;
    }

    &.is-open {
        .datepicker__label {
            color: var(--blue-600);
        }

        .datepicker__input {
            border-color: var(--blue-600);
        }

        &.has-error {
            border-color: var(--red-600);
        }
    }

    &.has-error {
        .datepicker__input {
            border-color: var(--red-600);
        }

        .datepicker__label {
            color: var(--red-600);
        }

        .error-icon {
            display: inline-block;
        }
    }


    .icon-fa {
        display: none;
        position: absolute;

        &.error-icon {
            color: var(--red-600);
        }
    }
}

.datePicker-error-message {
    font-size: 12px;
    color: var(--red-600);
    line-height: 1.25;
    display: block;
    padding: 3px 0 0 15px;
    position: absolute;
    max-width: 150px;
}