//ORDERBOOK
.KM_retailTrading {


    .toggleButtonsRow {
        display: flex;
        justify-content: space-between;
        flex: 1;
        background: var(--blue-100);
        border-radius: 6px;

        .toggleButtonItem {
            flex: 1;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;

            &.selected {
                background-color: var(--blue-200);
            }

            &:hover {
                background-color: var(--blue-200);
            }
        }
    }

    .segmentedControl-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    .retail-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
    }

}