/* This will re-declare variables globally */

.highcharts-text-outline {
  opacity: 0;
}

.highcharts-navigator-handle {
  fill: var(--chart-navigator-handles);
  stroke: none;
  border-radius: 6px;
}

.highcharts-tooltip {
  .highcharts-tooltip-box {
    .highcharts-label-box {
      fill: transparent;
    }
  }
}

.chart-tooltip {
  background: rgb(256, 256, 256, 0.85);
  border-radius: 6px;
  box-shadow: var(--box-shadow-active);
  padding: 5px 10px;
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  color: var(--dark-900);
  font-weight: 400;

  .tooltip-date {
    font-size: 12px;
  }

  .tooltip-title {
    font-weight: 700;
  }

  .tooltip-value {
    font-weight: 400;
  }
}

.highcharts-navigator-mask-outside {
  outline: var(--chart-navigator-border) solid 1px;
}