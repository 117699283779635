@import '_variables.scss';

.KCL_input {
    position: relative;
    font-weight: 400;

    &:hover,
    &:focus,
    &:active,
    &.is-focused {
        -webkit-box-shadow: 0 4px 4px 0 var(--hover-box-shadow-color);
        box-shadow: 0 4px 4px 0 var(--hover-box-shadow-color);
        border-radius: 6px;

        .KM_clear-input-icon .KM_clear-input-icon-button .clear-icon {
            color: var(--blue-600)
        }
    }

    &.full-width {
        width: 100%;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        appearance: textfield;
        -moz-appearance: textfield;
        text-align: right;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        outline: none;
        -webkit-box-shadow: 0 0 0 30px var(--white) inset;
    }

    input:focus-within {
        border: 1px solid var(--input-hover-color);
        box-shadow: 0px 4px 4px var(--hover-box-shadow-color);
    }

    input,
    textarea {
        border-radius: 6px;
        border: 1px solid var(--dark-300);
        box-shadow: 0px 4px 4px 0px var(--box-shadow-color);
        color: var(--dark-600);
        width: 100%;
    }

    textarea {
        min-height: 100px;
    }

    .search-icon {
        position: absolute;
        top: 14px;
        right: 15px;
        pointer-events: none
    }

    &.xsm {
        input {
            font-size: 11px;
            line-height: 13px;
            height: 20px;
            padding-left: 12px;
            padding-right: 12px;

            @include breakpoint-down(mobile-medium) {
                width: 80%;
            }

            @include breakpoint-down(mobile-small) {
                width: 100%;
            }
        }

        &.currency_input {
            width: 120px;
            float: right;

            input {
                width: 120px;
                padding-right: 28px;
            }
        }
    }

    &.sm {
        .input__label {
            top: 7px;
            left: 12px;
            font-size: 14px;
            line-height: 16px;
        }

        input {
            font-size: 13px;
            line-height: 15px;
            height: 30px;
            padding-left: 12px;
            padding-right: 12px;

            @include breakpoint-down(mobile-medium) {
                width: 80%;
            }

            @include breakpoint-down(mobile-small) {
                width: 100%;
            }
        }

        &.currency_input {
            width: 120px;
            float: right;

            input {
                width: 120px;
                padding-right: 28px;
            }
        }

        &.is-focused .input__label,
        &.has-value .input__label,
        :-webkit-autofill+.input__label {
            padding: 0 3px;
            top: -5px;
            font-size: 10px;
            line-height: 12px;

        }

        .icon-fa {
            font-size: 13px;
            right: 12px;
            top: 8px;
        }
    }

    &.lg {
        input {
            font-size: 16px;
            line-height: 19px;
            height: 45px;
            padding-left: 15px;
            padding-right: 15px;
        }

        textarea {
            font-size: 16px;
            line-height: 19px;
            padding: 13px 15px 0 15px;
        }

        .input__label {
            top: 13.5px;
            left: 15px;
            font-size: 16px;
            line-height: 18px;
        }

        &.is-focused .input__label,
        &.has-value .input__label,
        :-webkit-autofill+.input__label {
            padding: 0 3px;
            top: -5px;
            font-size: 11px;
            line-height: 13px;
        }

        .icon-fa {
            font-size: 16px;
            right: 15px;
            top: 14px;
        }
    }

    input:hover,
    textarea:hover {
        border-color: var(--input-hover-color);
        box-shadow: 0px 4px 4px var(--hover-box-shadow-color);
    }

    input:focus,
    textarea:focus {
        border-color: var(--input-hover-color);
        box-shadow: 0px 4px 4px var(--hover-box-shadow-color);
    }

    &.is-focused {
        .input__label {
            color: var(--input-hover-color);
        }
    }

    .input__label {
        pointer-events: none;
        position: absolute;
        color: var(--dark-600);
        background: #fff;
        z-index: 1;

        @include vendor-prefix(transition, (top .25s ease-out,
                font-size .25s ease-out,
                line-height .25s ease-out,
                padding .25s ease-out));

        @include breakpoint-up(tablet) {
            .main-label {
                display: inline;
            }

            .short-label {
                display: none;
            }
        }

        @include breakpoint-down(tablet) {
            .main-label {
                display: none;
            }

            .short-label {
                display: inline;
            }
        }
    }

    &.is-disabled {
        input {
            background: var(--dark-050) !important;
        }

        &:hover {
            box-shadow: none;
        }

        input:disabled,
        textarea:disabled {
            background: var(--dark-050) !important;
            box-shadow: none;

            &:hover {
                border: var(--dark-300) 1px solid;
                box-shadow: none;
            }
        }

        .input__label {
            color: var(--dark-300);
            background: var(--dark-050);
        }

        .search-icon {
            color: var(--dark-300);
        }
    }


    &.has-value input {
        color: var(--dark-900);
    }

    .icon-fa {
        display: none;
        position: absolute;

        &.ok-icon {
            color: var(--success-green);
        }

        &.error-icon {
            color: var(--error-red);
        }

        &.eye-icon {
            &.visible {
                display: inline-block;
            }

            &.fa-eye-slash {
                color: var(--dark-600);
                cursor: pointer;
            }

            &.fa-eye {
                color: var(--dark-600);
                cursor: pointer;
            }
        }
    }


    .input-error-message {
        font-size: 12px;
        color: var(--error-red);
        line-height: 1.25;
        display: block;
        padding: 10px 0 0 15px;
    }

    &.has-error {

        input,
        textarea {
            border-color: var(--error-red);
        }

        .input__label {
            color: var(--error-red);
        }

        .error-icon {
            display: inline-block;
        }

        .eye-icon.visible {
            display: none;
        }
    }

    ::placeholder {
        color: var(--dark-600);
    }
}

/* ------------------------------------------------------------ *\
	Checkbox
\* ------------------------------------------------------------ */

.KCL_checkbox {
    position: relative;

    //focused 
    &:focus-within {
        .checkbox__icon {
            outline: 4px solid var(--button-focus);
        }
    }

    input {
        appearance: none;
        border: none;
        border-radius: 0;
        background: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        opacity: 0;
        visibility: visible;
    }

    .checkbox__label {
        font-size: 16px;
        // line-height: 1.63;
        display: flex;
        align-items: center;
        user-select: none;
        transition: color .3s;
        cursor: pointer;
    }

    &.is-disabled {
        .checkbox__label {
            span {
                color: var(--checkbox-label-disabled-color);
            }
        }
    }

    .checkbox__icon {
        width: 22px;
        height: 22px;
        border-radius: 4px;
        border: 1px solid var(--dark-300);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
        align-self: flex-start;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        padding: 1px;
        flex-shrink: 0;
        background: #fff;
        transition: background .3s, color .3s, border .3s, box-shadow .3s;

        i {
            font-size: 15px;
            opacity: 0;
            visibility: hidden;
            transition: opacity .3s, visibility .3s;
        }
    }

    &:not(.is-disabled):hover {
        .checkbox__label {
            color: var(--checkbox-label-hover-color);
        }

        .checkbox__icon {
            border-color: var(--checkbox-label-hover-color);
            box-shadow: 0px 4px 4px rgba(34, 53, 223, 0.2);
        }

        // &.checked .checkbox__label {
        input:checked~.checkbox__label {
            color: var(--checkbox-label-hover-color);

            .checkbox__icon {
                background: var(--checkbox-label-hover-color);
            }
        }
    }

    // &.checked .checkbox__label {
    input:checked~.checkbox__label {
        color: var(--input-hover-color);

        i {
            opacity: 1;
            visibility: visible;
        }

        .checkbox__icon {
            border-color: var(--input-hover-color);
            background: var(--input-hover-color);
            box-shadow: 0px 4px 4px rgba(34, 53, 223, 0.2);
            color: #fff;
        }
    }

    input[disabled]~.checkbox__label {
        pointer-events: none;

        .checkbox__icon {
            background: var(--input-disabled-color);
        }
    }

    .input-error-message {
        font-size: 12px;
        color: var(--error-red);
        line-height: 1.25;
        display: block;
        padding: 3px 0 0 15px;
    }
}