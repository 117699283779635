footer:not(.bigFooter) {
    width: 100%;
    height: 100%;
    padding: 6px 14px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    background: var(--footer-background);
    color: var(--footer-text);
    overflow: hidden;

    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.clock-container {
    display: grid;
    grid-template-columns: 72% 28%;
}