/*Kodiak PRO OLD*/
/* Light mode colors */
:root {
  font-family: 'Roboto', sans-serif;
  --background-color: #F8F8FA;
  --background-color-light: #494646;
  --border-color: #494847;

  --button: #272525;
  --button-hover: #222121;
  --button-active: #1e1d1d;

  --red: #DB0033;
  --green: #038600;
  --orange: #C45200;

  /* //custom color  */
  --main-blue: #04182A;
  --blue-900: #1D3097;
  --blue-600: #2235DF;
  --blue-400: #4569EE;
  --blue-200: #D4DEFF;
  --blue-100: #F2F5FF;

  --green-600: #038600;
  --green-200: #C8E5CE;
  --green-100: #EFF7F0;

  --red-900: #A70027;
  --red-600: #DB0033;
  --red-400: #FF7697;
  --red-200: #FFD6E0;
  --red-100: #FFEFF3;

  --yellow-900: #EC9718;
  --yellow-600: #FFF06B;
  --yellow-400: #FFF06B;
  --yellow-200: #FEFBDC;
  --yellow-100: #FEFBDC;

  --dark-900: #232530;
  --dark-800: #393A45;
  --dark-600: #65666E;
  --dark-400: #94949A;
  --dark-300: #BDBEC1;
  --dark-200: #D3D3D6;
  --dark-100: #E9E9EA;
  --dark-050: #F1F2F5;

  --hover-box-shadow-color: #040DE333;
  --box-shadow-color: rgba(0, 0, 0, 0.04);
  --box-shadow-active: 0px 4px 4px 0px rgba(34, 53, 223, 0.20);
  --box-shadow-menu-dropdown: 0px 2px 30px 0px rgba(34, 53, 223, 0.50);

  --footer-background: #04182B;
  --footer-text: #fff;
  /* --white */
  --input-hover-color: #2235DF;

  --checkbox-label-disabled-color: #393A45;
  --checkbox-label-hover-color: #1D3097;

  --scrollbar-thumb: #1D3097;
  /* --blue-900 */
  --scrollbar-track: #D4DEFF;
  /* --blue-200 */

  --button-buy: #94EFB3;
  --button-buy-hover: #C2FFD7;
  --button-buy-focus: #FEF8B9;
  --button-buy-disabled: #EEFDF3;
  --button-danger: #94EFB3;
  --button-danger-hover: #C2FFD7;
  --button-danger-focus: #FEF8B9;
  --button-danger-disabled: #EEFDF3;
  --button-hover: #1D3097;
  --button-focus: #D4DEFF;

  --table-header-background: linear-gradient(180deg, #FFF 72.09%, #F7F7F9 96%);
  --table-header-border: #BDBEC1;
  /* --dark-300 */
  --table-header-text: #4F5059;
  /* --dark-800 */

  --table-header-svgIcon: #1D3097;
  /* --blue-900 */
  --table-header-svgIcon-hover: #D4DEFF;
  /* --blue-200 */

  --tab-active: #6CD3FF;
  /* CHART COMPONENT */
  --chart-axis-text: #7B7C83;
  --chart-axis-line: #D3D3D6;
  /* --dark-dark-200 */
  --chart-navigator-handles: #1D3097;
  /* --blue-900 */
  --chart-navigator-border: #E9E9EA;
  /* --dark-100 */

  /* Market status colors */
  --Market-status-trading: #038600;
  /* --green-600 */
  --Market-status-closed: #DB0033;
  /* --red-600 */
  --Market-status-other: #C45200;
  /* --orange */

  --Market-status-trading-light: linear-gradient(162deg, #17FF2F 6.25%, #006A0B 84.38%);
  --Market-status-closed-light: linear-gradient(162deg, #FF7697 6.25%, #A70027 84.38%);
  --Market-status-other-light: linear-gradient(162deg, #F8E113 6.25%, #FF5C00 84.38%);

  --golden-layout-splitter: #6CD3FF;
  --golden-layout-splitter-dragging: #2235DF;

  --window-linked: #393A45;
  --window-not-linked: #BDBEC1;
  --success-green: #038600;
  /* --green-600 */
  --error-red: #DB0033;

  --white: white;
}

html {
  font-size: 87.5%;
  /*81.25%;*/
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;

  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  /*font-size: 10em;/*/
}

h1 * {
  scrollbar-width: thin;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */

}

*:focus-visible {
  outline: none;
}


#root {
  width: 100vw;
  height: 100vh;
  display: grid;
}

.window {
  display: grid;
  grid-template-rows: 26px calc(100% - 26px);
  border: 1px solid var(--border-color);
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

/* Headings/H2 */
h2 {
  font-size: 38px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
}

/* Headings/H6 */
h6 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

td,
th {
  white-space: nowrap;
  padding: 0.25em;
}

td:has(svg) {
  padding: 0;
}

th {
  background-color: var(--background-color-light);
  font-weight: normal;
}

tr {
  border-bottom: 1px solid var(--border-color);
}

td {
  border-right: 1px solid var(--border-color);
}

td:first-child {
  border-left: 1px solid var(--border-color);
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  font-size: 0.7em;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paragraph-small,
.paragraph-small--bold,
.paragraph-small--italic {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: var(--dark-900);
}

.paragraph--bold,
.paragraph-small--bold {
  font-weight: 700 !important;
}

.fill {
  height: 100%;
  width: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: var(--dark-300);
  border-radius: 9px;
  /* make the thumb round */
  border: 2px solid var(--dark-300);
}

::-webkit-scrollbar-track {
  background: var(--dark-100);
}

::-webkit-scrollbar-corner {
  background: var(--dark-100);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--dark-600);
  border-color: var(--dark-600);
}

::-webkit-scrollbar-thumb:active {
  border-color: var(--dark-800);
  background-color: var(--dark-800);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.details-panel {
  max-height: fit-content;
}


.formik-error-message {
  color: var(--red-600);
  font-size: 11px;
  margin-left: 5px;
  position: absolute;
}

/* Public animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s ease-in-out infinite;
}

@keyframes new-row-highlight {
  from {
    background-color: var(--yellow-200);
  }

  to {
    background-color: transparent;
  }
}

button {
  background: transparent;
  border: none;
  text-align: left;
}