.KP_FileUpload {
    .dropZone {
        color: var(--blue-600);
        cursor: pointer;

        p {
            color: var(--Blue-600, #2235DF);
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 133.333% */
            letter-spacing: 0.6px;
            margin: 0;
        }

        .importIcon {
            font-size: 30px;
            color: var(--blue-400);
        }

        &.accepted {
            border: 2px dashed var(-green-600);
            background: var(--green-200);

            p {
                color: var(--green-600);
            }

            .importIcon {
                color: var(--green-600);
            }
        }

        &.rejected {
            border: 2px dashed var(-red-600);
            background: var(--red-200);

            p {
                color: var(--red-600);
            }

            .importIcon {
                color: var(--red-600);
            }
        }

    }

    .inputContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
        margin-top: 10px;
    }

    .buttonWrapper {
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }

    span.notranslate {
        line-height: 0;
    }
}