.language-dropdown {

    .language-item {
        display: flex;
        align-items: center;
        padding: 7px 10px;
        gap: 5px;

        cursor: pointer;
        // margin-bottom: 2px;

        &:not(:last-child) {
            margin-bottom: 2px;
        }

        &:hover {
            background-color: var(--blue-100);
        }
    }
}

.language-dropdown-button {
    display: flex;
    align-items: center;
}