$breakpoints: (
	mobile-small: 374px,
	mobile-smedium: 474px,
	mobile-medium: 575px,
	mobile: 767px,
	tablet: 1023px,
	small-desktop: 1199px,
	medium-desktop: 1439px,
	medium-large-desktop: 1540px,
	large-desktop: 1640px,
	very-large-desktop: 2160px
);

$responsive-ios             : 812px;

@function breakpoint-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n != null and $n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min + .02, null);
}


@function breakpoint-max($name, $breakpoints: $breakpoints) {
	$max: map-get($breakpoints, $name);
	@return if($max != 0, $max, null);
}


@mixin breakpoint-up($name, $breakpoints: $breakpoints) {
	$min: breakpoint-min($name, $breakpoints);

	@media (min-width: $min) {
		@content;
	}
}

@mixin breakpoint-down($name, $breakpoints: $breakpoints) {
	$max: breakpoint-max($name, $breakpoints);

	@if $max == 767px {
		@media (max-width: $max), screen and (max-width: $responsive-ios) and (orientation: landscape) {
			@content;
		}
	} @else {
		@media (max-width: $max) {
			@content;
		}
	}
}

@mixin breakpoint-between($lower, $upper, $breakpoints: $breakpoints) {
	$min: breakpoint-min($lower, $breakpoints);
	$max: breakpoint-max($upper, $breakpoints);

	@media (min-width: $min) and (max-width: $max) {
		@content;
	}
}
@mixin vendor-prefix($attribute, $value) {
	$vendors: '-webkit-', '-moz-', '-ms-', '-o-', '';
	@each $vendor in $vendors {
	  #{$vendor}#{$attribute}: #{$value};
	}
  }

