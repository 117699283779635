.KCL_checkbox {
	position: relative;
	width: fit-content;
	display: flex;
	align-items: center;

	input {
		appearance: none;
		border: none;
		border-radius: 0;
		background: transparent;
		position: absolute;
		top: 0;
		left: 0;
		width: 1px;
		height: 1px;
		opacity: 0;
		visibility: visible;
	}

	.checkbox__label {
		font-size: 16px;
		display: flex;
		align-items: center;
		user-select: none;
		transition: color .3s;
	}

	&.is-disabled {
		.checkbox__label {
			span {
				color: var(--dark-900);
			}
		}
	}

	.checkbox__icon {
		width: 22px;
		height: 22px;
		border-radius: 4px;
		border: 1px solid var(--dark-300);
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
		align-self: flex-start;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
		padding: 1px;
		flex-shrink: 0;
		background: #fff;
		transition: background .3s, color .3s, border .3s, box-shadow .3s;

		i {
			font-size: 15px;
			opacity: 0;
			visibility: hidden;
			transition: opacity .3s, visibility .3s;

			.fa-check {
				color: var(--white);
			}
		}
	}

	&:not(.is-disabled):hover {
		.checkbox__label {
			color: var(--blue-900);
		}

		.checkbox__icon {
			border-color: var(--blue-900);
			box-shadow: 0px 4px 4px rgba(34, 53, 223, 0.2);
		}

		input:checked~.checkbox__label {
			color: var(--blue-900);

			.checkbox__icon {
				background: var(--blue-900);
			}
		}
	}

	input:checked~.checkbox__label {
		color: var(--blue-600);

		i {
			opacity: 1;
			visibility: visible;
		}

		.checkbox__icon {
			border-color: var(--blue-600);
			background: var(--blue-600);
			box-shadow: 0px 4px 4px rgba(34, 53, 223, 0.2);
		}
	}

	input[disabled]~.checkbox__label {
		pointer-events: none;

		.checkbox__icon {
			background: var(--dark-050);
		}
	}

	.input-error-message {
		font-size: 12px;
		color: var(--red-600);
		line-height: 1.25;
		display: block;
		padding: 3px 0 0 15px;
	}

	&.sm {
		.checkbox__label {
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		.checkbox__icon {
			width: 15px;
			height: 15px;
			margin-right: 6px;

			i {
				font-size: 12px;

				.fa-check {
					font-size: 10px;
				}
			}
		}
	}
}