.menu-header {
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;

        >span.btn-wrapper {
            position: relative;
            line-height: 0;
            right: 25px;
            cursor: pointer;
        }

    }
}

.category-dropdown {
    &.isOpen {
        border-bottom: 0px !important;
        border-radius: 6px 6px 0 0;

        fieldset {
            border-radius: 6px 6px 0 0;
            border-color: var(--blue-600);
            border-bottom: none;
        }
    }
}

.KCL_news {
    display: flex;
    flex-direction: column;

    .content {
        flex-grow: 1;
        overflow: auto;

        &.no_data {
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                color: #7a7777;
            }
        }

        .frettavakt-item {
            .news-item__head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: var(--dark-100) solid 1px;
                // padding: 6px 5px;
                padding: 4px 5px;
                column-gap: 15px;
                min-height: 25px;

                &:hover {
                    background: var(--blue-100);
                    // outline: var(--dark-100) solid 1px;
                    cursor: pointer;
                }

                .news-item__title {
                    color: var(--dark-900, #232530);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                .news-item__source {
                    color: var(--dark-600, #65666E);
                    text-align: right;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .news-item__time {
                    color: var(--dark-900, #232530);
                    text-align: right;
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }

            &.is-seen:not(.is-open) {
                .news-item__title {
                    font-weight: 400;
                }
            }

            .news-item__meta {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                text-align: right;
                gap: 15px;
            }

            .news-item__trigger {
                display: flex;
                gap: 5px;

                .news-item__icon {
                    color: var(--blue-400);
                    margin-right: 6px;
                }

                .notify-wrapper {
                    .news-item__notify {
                        width: 6px;
                        height: 6px;
                        display: block;
                        border-radius: 50%;
                        background: var(--Yellow-Yellow-600, #F8C513);
                    }
                }
            }

            .news-item__body {
                display: flex;
                flex-direction: column;
                background: var(--white);
                padding: 5px;
                border-bottom: var(--dark-100) solid 1px;

                .news-item__entry {
                    color: var(--dark-900, #232530);
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 15px;

                    /* 136.364% */
                    img {
                        margin: 10px !important;
                        margin-right: 0px !important;
                        min-width: 250px;
                        width: 33%;
                        float: right;
                        clear: both;
                    }

                }

                .news-item__actions {
                    margin-top: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .news-item__labels {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }
                }
            }

            &.is-new {
                animation: new-row-highlight 10s forwards;
            }

            &.is-open {
                .news-item__head {
                    background-color: var(--blue-100);
                    border-bottom: var(--dark-100) solid 1px;
                }

                .news-item__body {
                    background: var(--white);
                }
            }
        }
    }

    .news-list__footer {
        border-top: var(--dark-100) solid 1px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 5px;

        .news-list__pagination {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .news-list__indicator {
                color: var(--dark-600, #65666E);
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .news-list__arrows {
                .icon-fa {
                    color: var(--dark-900, #232530);

                    &.disabled {
                        color: var(--dark-200, #D3D3D6);
                    }
                }
            }

            .pagination {
                margin: 0 7px;

                line-height: 0;
            }
        }
    }
}