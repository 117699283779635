footer.bigFooter {
  padding: 66px 0 0;
  background-color: var(--main-blue);
  color: #fff;
  width: 100%;

  @include breakpoint-down(tablet) {
    padding: 50px 0 0;
  }

  .shell {
    max-width: 1720px;
    margin: 0 auto;
    padding: 0 40px;

    @include breakpoint-down(medium-desktop) {
      padding: 0 30px;
    }
  }

  .footer__inner {
    padding: 0 0 54px;
    border-bottom: 1px solid rgba(255,255,255,.2);

    @include breakpoint-down(large-desktop) {
      margin: 0;
    }

    @include breakpoint-down(medium-desktop) {
      padding: 0 0 55px;
    }

    @include breakpoint-down(tablet) {
      flex-wrap: wrap;
      align-items: center;
    }

    @include breakpoint-down(mobile) {
      padding-bottom: 20px;
    }
  }

  .footer-logo {
    margin: 0 0 34px;

    @include breakpoint-down(medium-desktop) {
      padding: 0 0;
    }

    @include breakpoint-down(tablet) {
      text-align: center;
      margin-bottom: 26px;

      svg {
        width: 26px;
        height: 26px;
      }
    }
  }

  .footer-cols {
    margin: 0 -13px;
    display: flex;
    justify-content: space-between;

    @include breakpoint-down(tablet) {
      flex-wrap: wrap;
    }
  }
  
  .footer-col {
    font-size: 15px;
    padding: 0 13px;
    width: 16.67%;

    @include breakpoint-down(tablet) {
      font-size: 14px;
      width: 25%;
      &.social-media {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 20px;
      }
    }

    @include breakpoint-down(mobile) {
      width: 50%;
      margin-bottom: 30px;
    }

    p {
      margin: 0 0 18px;

      @include breakpoint-down(tablet) {
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: none;
    }

    li {
      margin-bottom: 18px;

      @include breakpoint-down(tablet) {
        margin-bottom: 12px;
      }
    }

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .link {
      &:not(:last-child) {
        margin: 0 0 18px;
      }

      a {
        display: block;
        transition: opacity 0.3s;

        &:hover {
          opacity: .7;
        }
      }
    }
  }

  .footer-col--1of4 {
    width: 25%;

    @include breakpoint-down(mobile) {
      width: 50%;
    }
  }

  .footer-col__address {
    @include breakpoint-down(tablet) {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;

      p:last-child {
        margin-bottom: 0;
      }
    }

    @include breakpoint-down(mobile) {
      margin-bottom: 50px;
    }
  }

  .footer__bottom {
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
    font-size: 14px;
    color: var(--white);

    @include breakpoint-down(mobile) {
      padding: 15px 0;
      display: block;
      font-size: 13px;
    }

    p {
      margin: 0;

      @include breakpoint-down(mobile) {
        margin-bottom: 20px;
      }
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
      
      svg {
        margin: 0 10px 0 0;
      }
    }
  }

  @media print {
    display: none;
  }
}
