.KM_NewOrderModal {
    .modal_body {
        display: grid;
        grid-template-columns: 116px 160px 140px 140px;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 20px 10px;
        grid-auto-flow: row;
        grid-template-areas:
            ". . . ."
            ". . hidden hidden"
            ". . . clear-all";
        margin-bottom: 30px;
    }

    .hidden {
        grid-area: hidden;
        margin-top: 6px;
    }

    .clear-all {
        justify-self: end;
        grid-area: clear-all;
    }

    .button-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-top: 2px solid var(--dark-200);
        padding-top: 10px;
        flex-direction: row-reverse;
        width: 100%;
        gap: 15px;
    }

    .symbol-info-section {
        display: flex;
        justify-content: flex-end;
        gap: 15px;

        .info-title {
            color: var(--dark-900, #232530);
            font-size: 9px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-right: 5px;
        }

        .info-value {
            color: var(--dark-900, #232530);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
}