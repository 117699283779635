.KCL_new_password-page {
    background: linear-gradient(96deg, #04182B 4.3%, #0C285F 37.13%, #29175B 76.87%);
    overflow-y: auto;
    .section {
        height: 100vh;
        
        .section__body{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding-top: 100px;
            row-gap: 100px;
            height: 100%;
        }
    }
}

.new_password_container{
    background: var(--white);
    max-width: 530px;
    border-radius: 24px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    padding: 40px 50px 60px;
    margin: 0 auto;
    width: 100%;

    .login-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;

        .form__section {
            width: 100%;
        }

        .form__body,
        .form__actions {
            max-width: 304px;
            margin: 0 auto;
        }
        .form__body{
            margin-bottom: 30px;
        }

        .form__actions {
            display: flex;
            margin-bottom: 25px;
            
            .KCL_button {
                flex-grow: 1;
                margin: 0;
            }
        }
    }

    h2 {
        text-align: center;
        margin-bottom: 30px;
        color: var(--dark-900);
    }

    p {
        text-align: center;
        color: var(--dark-800);
        margin-bottom: 5px;
    }

    .keldan__links {
        display: flex;
        grid-gap: 20px;
        gap: 20px;
        justify-content: center;
    }
}