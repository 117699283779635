.KM_collapsible {

    .collapsible-row {
        cursor: pointer;
        padding: 5px;
        border-radius: 6px;

        &:hover {
            background-color: var(--blue-100);
            color: var(--blue-600);
        }
    }

    section {
        padding-left: 10px;
    }
}