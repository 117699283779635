// .lm_controls .lm_close {
//     background-image: url(icons8-close-11.svg);
//     background-size: 13px;
//     filter: invert(1);
// }

// .lm_header .lm_tab .lm_close_tab {
//     background-image: url(icons8-close-11.svg);
//     background-size: 13px;
//     filter: invert(1);
// }

// .lm_header .lm_tab.lm_active .lm_close_tab {
//     opacity: 0.7;
// }
// .lm_root {
//     background-color: transparent;
// }
// .lm_drag_handle {
//     background-color: transparent;
// }
// .lm_splitter.lm_horizontal {
//     background-color: transparent;
// }
// .lm_item.lm_row{
//     background-color: transparent;
// }

// .lm_splitter.lm_vertical {
//     margin-top: 20px;
// }
.lm_content {
    border-radius: 0px 0px 6px 6px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
}

.lm_splitter:hover {
    background: var(--golden-layout-splitter);
}

.lm_splitter.lm_dragging {
    background: var(--golden-layout-splitter-dragging)
}

.lm_stack {
    background-color: var(--white);
    border-radius: 6px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);

    .lm_header {
        background-color: var(--white);
        display: flex;
        align-items: flex-end;
        height: 30px !important;
        border-radius: 6px 6px 0 0;
        margin-left: 7px;
        margin-right: 7px;
        border-bottom: 1px solid var(--dark-300);

        //tabs
        .lm_tabs {
            background-color: var(--white);

            .lm_tab {
                background-color: var(--white);
                color: black;

                &:first-child {
                    padding-left: 0px;
                }

                // default text style
                color: var(--dark-600, #65666E);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                padding-right: 15px;

                &.lm_active {
                    //text color
                    color: var(--dark-900, #232530);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    padding-right: 7px;

                    &::after {
                        content: "";
                        background-color: var(--tab-active);
                        height: 5px;
                        padding-right: 7px;
                        width: 100%;
                        display: inline-block;
                        top: -9px;
                        position: relative;
                    }

                }

                .lm_close_tab {
                    background-image: url(CloseIcon.svg);
                    width: 8px;
                    height: 8px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-top: 2px;
                    filter: invert(0);
                }
            }
        }

        .lm_controls {
            right: 0px;

            .lm_close {
                background-image: url(CloseIcon.svg);
                // background-size: 8px;
                width: 12px;
                height: 12px;
                background-repeat: no-repeat;
                background-size: contain;
                filter: invert(0);
                margin-bottom: 8px;
            }
        }
    }

    //MAIN CONTENT
    .lm_content {
        background-color: var(--white);

        .window {
            border: none;
            // padding-left: 5px;
            // padding-right: 5px;
            // margin-top: 5px;
            padding: 5px;

        }
    }
}


//DRAGABLE OBJECT STYLES
.lm_dragProxy .lm_content {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    background-color: var(--white);
}

.lm_dragProxy.lm_bottom .lm_header .lm_tab,
.lm_stack.lm_bottom .lm_header .lm_tab {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    // box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3)
}

.lm_dragProxy.lm_bottom .lm_header .lm_tab.lm_active,
.lm_stack.lm_bottom .lm_header .lm_tab.lm_active {
    // box-shadow: 0 2px 2px #000000
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);

}

.lm_dropTargetIndicator {
    border-radius: 6px;

    outline: 2px dashed var(--blue-600, #2235DF);
    background: var(--White, #FFF);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    transition: all 200ms ease;
}

.lm_dropTargetIndicator .lm_inner {
    background: #000000;
    opacity: .2;
    border-radius: 6px;
}