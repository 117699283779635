.KM_marketStatus{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    flex-wrap: nowrap;

    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    .status_light{
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
}