/* ------------------------------------------------------------ *\
    Radio
\* ------------------------------------------------------------ */

.radio {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;


  input {
    appearance: none;
    border: none;
    border-radius: 0;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
    visibility: hidden;
  }

  .radio__label {
    position: relative;
    display: flex;
    align-items: center;
    user-select: none;
    color: var(--dark-900);
    transition: background .3s, color .3s;
    cursor: pointer;

  }

  .radio__icon {
    position: relative;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    border: 1px solid var(--dark-300);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
      font-size: 22px;
      line-height: 0;
      opacity: 0;
      transition: opacity .3s;
    }
  }

  &.xsm {
    font-size: 14px;
    line-height: 22px;

    .radio__icon {
      width: 12px;
      height: 12px;

      span {
        font-size: 12px;
      }
    }

    .radio__label {
      span:not(.radio__icon) {
        font-size: 12px;
      }
    }
  }

  &.sm {
    .radio__icon {
      width: 14px;
      height: 14px;

      span {
        font-size: 14px;
      }
    }

    .radio__label {
      span:not(.radio__icon) {
        font-size: 14px;
      }
    }
  }

  input:checked~.radio__label {
    color: var(--blue-600);
  }

  &.is-disabled {
    pointer-events: none;

    .radio__label {
      color: var(--dark-300);

      .radio__icon {
        background: var(--dark-050);
      }
    }

    input:checked~.radio__label {
      color: var(--blue-200);
    }
  }

  input:checked~.radio__label .radio__icon span {
    opacity: 1;
  }
}

/* ------------------------------------------------------------ *\
  Radios Group
\* ------------------------------------------------------------ */

.radios-group {
  ul {
    list-style: none;
    column-gap: 5px;

    li {
      break-inside: avoid;
      margin-bottom: 5px;
    }
  }
}