.KCL_alert {
    border: 1px solid;
    border-radius: 6px;
    text-align: left;
    max-width: 946px;

    &.success {
        background: var(--green-100);
        border-color: var(--green-200);

        .icon {
            color: var(--green-600);
        }

        .close-button {
            color: var(--green-600);
        }
    }

    // &.info {
    //     background: $c-teal-f2;
    //     border-color: $c-blue-d4;

    //     .icon {
    //         color: $c-blue-22;
    //     }

    //     .close-button {
    //         color: $c-blue-22;
    //     }
    // }

    &.alert, &.reconnecting {
        background: var(--yellow-200);
        border-color: var(--yellow-600);

        .icon {
            color: var(--yellow-900);
        }

        .close-button {
            color: var(--yellow-900);
        }
    }

    &.error {
        background: var(--red-100);
        border-color: var(--red-200);

        .icon {
            color: var(--red-600);
        }

        .close-button {
            color: var(--red-600);
        }
    }

    &.sm {
        padding: 20px;
    }

    &.lg {
        padding: 30px;
    }

    &.dismissed {
        display: none;
    }

    .alert-header {
        display: flex;
        justify-content: space-between;

        .icon-and-header {
            display: flex;

            .head-text {
                margin-top: 0;
                margin-left: 15px;
                margin-bottom: 0;
            }

            .icon {
                font-size: 24px;
            }
        }

        .close-button {
            background: none;
            border: none;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;

            svg {
                font-size: 20px;
            }
        }
    }

    .main-text {
        margin-left: 39px;
        margin-top: 6px;
    }
}