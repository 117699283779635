.market-depth {
    display: flex;
    flex-direction: column;
    // grid-template-rows: 20px 22px calc(100% - 52px);
    /* border: 1px solid var(--border-color); */
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    overflow: hidden;
}

.autocomplete_wrapper {
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
    align-items: center;
}

.header-info {
    display: flex;
    align-items: baseline;
    // flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    padding: 0 4px;

    color: var(--dark-900, #232530);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom: 2px solid var(--dark-100);

    >div.header-info-item {
        display: flex;
        flex-direction: row;
        margin-right: 1px;

        .header-text {
            color: var(--dark-900, #232530);
            font-size: 9px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
            margin-right: 2px;
        }

        .delta_value {
            white-space: nowrap;
        }

    }
}

.market-depth-bar {
    width: 100%;
    height: 14px;
    background: var(--red-200);
    position: relative;
    border-radius: 6px;

    &__volume {
        position: absolute;
        font-weight: 500;
        font-size: 10px;

        &--b {
            left: 5px;
            color: var(--green-600);
        }

        &--a {
            right: 5px;
            color: var(--red-600);
        }
    }

    &__progress {
        height: 100%;
        background: var(--green-200);
        border-radius: 6px 0px 0px 6px;
        transition: width 0.5s ease-in-out;
    }
}

.market-depth-bar__counts {
    display: flex;
    justify-content: space-between;
    margin-top: 3px;
    padding: 0 5px;
    font-size: 10px;
}