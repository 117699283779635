@keyframes up-animation-1 {
    0% {
        background-color: var(--green-200);
        color: var(--green-600);
    }
}

@keyframes up-animation-0 {
    0% {
        background-color: var(--green-200);
        color: var(--green-600);
    }
}


@keyframes down-animation-1 {
    0% {
        background-color: var(--red-200);
        color: var(--red-600);
    }
}

@keyframes down-animation-0 {
    0% {
        background-color: var(--red-200);
        color: var(--red-600);
    }
}

//TABLE
//Rightclick menu styles
.menu-symbol {
    display: flex;
    padding: 3px 20px 3px 10px;
    align-items: center;
    gap: 8px;
    align-self: stretch;

    >span {
        color: var(--dark-900, #232530);

        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
    }
}