.KM_RetailTradingButton {
    color: white;
    padding: 2px 10px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.10);
    cursor: pointer;

    &:hover {
        background: rgba(255, 255, 255, 0.20);
    }
}

.KM_RetailTradingModal {
    padding: 20px;
}