:root {
    // toastify overrides 

    //error
    --toastify-color-error: var(--red-100) !important;
    --toastify-icon-color-error: var(--red-600) !important;
    --toastify-text-color-error: var(--dark-900) !important;

    //success
    --toastify-color-success: var(--green-100) !important;
    --toastify-icon-color-success: var(--green-600) !important;
    --toastify-text-color-success: var(--dark-900) !important;

    //warning
    --toastify-color-warning: var(--yellow-100) !important;
    --toastify-icon-color-warning: var(--yellow-900) !important;
    --toastify-text-color-warning: var(--dark-900) !important;

}

.Toastify__toast-theme--colored {

    &.Toastify__toast--error {
        border: var(--red-200) solid 1px;

        .Toastify__close-button>svg {
            color: var(--red-600);
        }
    }

    //success
    &.Toastify__toast--success {
        border: var(--green-200) solid 1px;

        .Toastify__close-button>svg {
            color: var(--green-600)
        }
    }

    //warning
    &.Toastify__toast--warning {
        border: var(--yellow-200) solid 1px;

        .Toastify__close-button>svg {
            color: var(--yellow-900)
        }
    }
}

.Toastify__toast-icon {
    font-size: 20px;
    width: 25px;
}