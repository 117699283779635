Form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    .form__section {
        width: 100%;
    }

    .form__body,
    .form__actions {
        max-width: 304px;
        margin: 0 auto;
    }
    .form__body{
        margin-bottom: 30px;
    }

    .form__actions {
        display: flex;
        margin-bottom: 25px;
        
        .KCL_button {
            flex-grow: 1;
            margin: 0;
        }
    }
}

h2,h6 {
    text-align: center;
}
h2 {
    color: var(--dark-900);
}
h6 {
    color: var(--dark-800);
    margin-bottom: 22px
}
