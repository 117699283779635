.KCL_link {
    // Always
    padding: 0;
    background: transparent;
    text-decoration: none;

    width: fit-content;

    // Always
    font-weight: 500;
    transition: color .3s, box-shadow .3s;
    box-shadow: inset 0 -0.5em 0 var(--blue-100);
    // Initial
    color: var(--blue-900);

    &:not(.split-lines) {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &.font-size--12 {
        font-size: 12px;
        line-height: 16px;
    }
    &.font-size--13 {
        font-size: 13px;
        line-height: 17px;
    }
    &.font-size--14 {
        font-size: 14px;
        line-height: 18px;

        @include breakpoint-down(mobile) {
            font-size: 12px;
            line-height: 16px;
        }
    }
    &.font-size--15 {
        font-size: 15px;
        line-height: 19px;

        @include breakpoint-down(mobile) {
            font-size: 13px;
            line-height: 17px;
        }
    }
    &.font-size--16 {
        font-size: 16px;
        line-height: 20px;

        @include breakpoint-down(mobile) {
            font-size: 14px;
            line-height: 18px;
        }
    }
    &.font-size--18 {
        font-size: 18px;
        line-height: 22px;

        @include breakpoint-down(mobile) {
            font-size: 16px;
            line-height: 20px;
        }
    }
    &.font-size--20 {
        font-size: 20px;
        line-height: 24px;

        @include breakpoint-down(mobile) {
            font-size: 18px;
            line-height: 22px;
        }
    }

    @include breakpoint-down(mobile-smedium) {
        &.wrap-text-for-mobile {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
            max-width: 72px;
        }
    }
    @include breakpoint-down(mobile-small) {
        &.wrap-text-for-mobile {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
            max-width: 65px;
        }
    }

    // Hover
    &:hover {
        color: var(--blue-600);
        box-shadow: inset 0 calc(-1em - 8px) var(--blue-100);
    }
    // Focus
    &:focus {
        color: var(--blue-900);
        box-shadow: inset 0 -0.5em var(--blue-200);
        // Focus and hover
        &:hover {
            color: var(--blue-600);
            box-shadow: inset 0 calc(-1em - 8px) var(--blue-200);
        }
    }
    &:focus-visible {
        color: var(--blue-600);
        box-shadow: inset 0 calc(-1em - 8px) var(--blue-200);
        outline: none;
    }

    &.is-disabled {
        pointer-events: none;
        opacity: 0.7;
    }

    > .svg-inline--fa {
        margin-right: 10px;
    }
}