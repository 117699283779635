.KCL_label {
    font-weight: 500;
    border-radius: 6px;
    width: fit-content;
    height: fit-content;
    user-select: none;
    cursor: default;

    &.sm {
        font-size: 8px;
        line-height: 12px;
        padding: 2px 4px;
    }

    &.md {
        font-size: 10px;
        line-height: 12px;
        padding: 2px 4px 1px 4px;
    }

    &.lg {
        font-size: 14px;
        line-height: 22px;
        padding: 2px 8px;
    }

    .full-text {
        display: none;
    }

    a {
        text-decoration: none;
    }

    &.origin-label {
        color: var(--dark-700);
        background: var(--dark-050);

        &:hover {
            color: var(--dark-800);

            a {
                color: var(--dark-800);
            }
        }
    }

    // &.disclaimer-label {
    //     color: $c-dark-23;
    //     background: $c-blue-d4;

    //     &:hover {
    //         color: $c-blue-1d;
    //         cursor: pointer;
    //     }
    // }

    // &.delay-label {
    //     color: $c-dark-23;
    //     background: $c-green-94-l;
    // }

    // &.closed-label {
    //     color: $c-dark-23;
    //     background: $c-red-ffd;
    // }

    &.beta-label {
        color: var(--white);
        background: var(--blue-400);
    }

    // &.new-label {
    //     color: $c-blue-1d;
    //     background: $c-yellow-ff;
    // }

    // &.yes-label {
    //     color: $c-dark-23;
    //     background: $c-yellow-f8;
    // }

    // &.no-label {
    //     color: $c-dark-23;
    //     background: $c-grey-d3;
    // }

    // &.news-release-label {
    //     color: $c-dark-23;
    //     background: $c-blue-6c-light;
    // }

    @include breakpoint-down(mobile-medium) {
        font-size: 8px;
    }
}