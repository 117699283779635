.KCL_button {
    font-weight: 700;
    text-align: center;
    border-radius: 6px;
    margin: 4px;
    transition: background 0.3s, color .3s;
    text-decoration: none;
    display: inline-block;
    white-space: pre;
    height: fit-content;
    cursor: pointer;

    &.noMargin {
        margin: 0px
    }

    &.disabled {
        pointer-events: none;
    }

    &.micro {
        font-size: 11px;
        line-height: 10px;
        padding: 4px 8px;
        font-weight: 500;

        &.with-icon {
            padding: 6px 11px;

            .icon-fa {
                margin-right: 7px;
            }
        }
    }

    &.xsm {
        font-size: 12px;
        line-height: 16px;
        padding: 4px 12px;

        &.with-icon {
            padding: 6px 11px;

            .icon-fa {
                margin-right: 7px;
            }
        }
    }

    &.sm {
        font-size: 14px;
        line-height: 16px;
        padding: 6px 14px;

        &.with-icon {
            padding: 6px 11px;

            .icon-fa {
                margin-right: 7px;
            }
        }
    }

    &.lg {
        font-size: 16px;
        line-height: 19px;
        padding: 12px 23px;

        &.with-icon {
            padding: 12px 13px;

            .icon-fa:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    &.primary {
        background: var(--blue-600);
        color: var(--white);
        border: 1px solid var(--blue-600);

        &:hover {
            background: var(--button-hover);
            color: var(--white);
            border-color: var(--button-hover);
        }

        &:focus {
            outline: 4px solid var(--button-focus);
        }

        &.disabled {
            background: var(--button-focus);
            border-color: var(--button-focus);
        }
    }

    &.danger {
        background: var(--red-600);
        color: var(--white);
        border: 1px solid var(--red-600);

        &:hover {
            background: var(--red-900);
            color: var(--white);
            border-color: var(--red-900);
        }

        &:focus {
            outline: 4px solid var(--red-200);
        }

        &.disabled {
            background: var(--red-200);
            border-color: var(--red-200);
        }
    }

    &.buy {
        background: var(--button-buy);
        color: var(--button-hover);
        border: 1px solid var(--button-buy);

        &:hover {
            background: var(--button-buy-hover);
            color: var(--button-hover);
            border-color: var(--button-buy-hover);
        }

        &:focus {
            outline: 4px solid var(--button-buy-focus);
        }

        &.disabled {
            background: var(--button-buy-disabled);
            border-color: var(--button-buy-disabled);
            color: var(--button-focus);
        }
    }

    &.secondary {
        background: transparent;
        color: var(--blue-600);
        border: 1px solid var(--blue-600);

        &:hover {
            color: var(--button-hover);
            border-color: var(--button-hover);
        }

        &:focus {
            outline: 4px solid var(--button-focus);
        }

        &.disabled {
            color: var(--button-focus);
            border-color: var(--button-focus);
        }
    }


    &.hide-text-in-mobile {
        @include breakpoint-down(tablet) {
            .icon-fa {
                margin-right: 0 !important;
            }

            .text-wrapper {
                display: none !important;
            }
        }
    }

    &.smaller-button-in-mobile {
        @include breakpoint-down(tablet) {
            font-size: 14px;
            line-height: 16px;
            padding: 6px 14px;

            &.with-icon {
                padding: 6px 11px;

                .icon-fa {
                    margin-right: 7px;
                }
            }
        }
    }

    &.fullWidth {
        width: 100%;
    }
}