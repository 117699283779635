.KM_dropdownIcon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 4px;

    .KM_dropdownIcon {
        color: var(--dark-400);
        font-size: 10px;
        transition: transform 0.3s ease, color 0.3s ease;
        pointer-events: none;

        &.open {
            transform: rotate(180deg);
        }

        &.focused {
            color: var(--blue-600);
        }


    }

    &:hover,
    &.open {
        background-color: var(--blue-100);
        border-radius: 50%;

    }
}