.keldan_pro_SettingsModal {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .modal_title {
        color: var(--dark-900, #232530);
        /* Mobile/Headings/H3 */
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 36px;
        /* 150% */
    }

    .settings_modal_item {
        display: flex;
        flex-direction: column;
        row-gap: 6px;

        .title {
            color: var(--dark-700, #4F5059);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.48px;
            text-transform: uppercase;
        }

        .value {
            color: var(--dark-900, #232530);
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .settings_modal_item-wrapper {
        display: flex;
        flex-direction: row;
        column-gap: 50px;
    }

    table {
        width: 100%;
        text-align: left;

        th,
        td {
            padding-left: 5px;
        }

        th {
            border: 1px solid var(--border-color);
        }
    }

    .marketDataConnections_table {
        th:first-child {
            width: 50px;
            /* Replace with the width you want */
        }

        td:first-child {
            text-align: center;
            vertical-align: middle;
        }

        th,
        td {
            white-space: unset;
        }
    }

    .deleteButton {
        width: fit-content;

        .deleteCacheBtn {
            path {
                color: black;
            }
        }

        &:hover {
            .deleteCacheBtn {
                path {
                    color: white;
                }
            }
        }
    }
}