.KMW_BondCalculator {
    &.window {
        display: block;
    }

    .KMW_Calculator-form {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 20px;
        margin-top: 20px;

        .bondInfo {
            display: flex;
            flex-direction: column;

            .bondInfoLabel {
                font-weight: 500;
            }
        }

        .radio_input {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .KCL_input {
                width: 120px
            }
        }
    }

    .error-button_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 4px;
        border-top: 1px solid var(--dark-300);

        gap: 15px;

        .error_message {
            color: var(--error-red);
            font-size: 12px;
            flex-grow: 1;
            flex-basis: 150px;
        }

        .button_container {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-basis: 200px;
            flex-grow: 1;
        }
    }

    .flex-container {
        display: flex;
        flex-wrap: wrap;
        row-gap: 2px;
        column-gap: 20px;
        width: 100%;
        /* Optional: Add some space between the flex items */
    }

    .left-container,
    .right-container {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 2px;
        flex-basis: 100px;
    }

    .left-container {
        flex: 1 1 calc(40% - 10px);
    }

    .right-container {
        flex: 1 1 calc(60% - 10px);
    }

    .flex-item {
        box-sizing: border-box;
        flex: 1;
    }

    .bondInfo_container {
        border: var(--dark-300) 1px solid;
        font-size: 12px;
        padding: 5px;

        .left-container {
            flex: 1 1 calc(50% - 10px);

            .flex-item {
                display: flex;
                justify-content: space-between;
                gap: 5px;
            }
        }

        .right-container {
            flex: 1 1 calc(50% - 10px);

            .flex-item {
                display: flex;
                justify-content: space-between;
                gap: 5px;
            }
        }

        .label {
            font-weight: 500;
        }
    }
}