.KM_modal {
    h1 {
        color: var(--dark-900, #232530);
        /* Mobile/Headings/H3 */
        font-size: 24px;
        font-style: normal;
        font-weight: 300;
        line-height: 36px;
        /* 150% */
        margin-bottom: 20px;
    }
}

.KM_modalButton.MuiButton-root {
    display: inline-flex;
    padding: 13px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: var(--blue-600);
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 6px;
    max-height: 45px;

    &:hover {
        background: var(--white);
        color: var(--blue-600);
        border-color: var(--blue-600);
    }
}

.KM_loadModal {
    min-width: 368px;
    overflow: hidden;

    .workspace_item {
        display: flex;
        padding: 10px 15px;
        align-items: center;
        gap: 10px;
        border-radius: 10px;

        &:hover {
            background: var(--blue-100);
            cursor: pointer;
        }

        .fa-icon {
            font-size: 18px;
            color: var(--Blue-600, #2235DF);
        }

        .workspace_name {
            color: var(--Blue-900, #1D3097);
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

    }
}

.KM_saveModal {
    min-width: 623px;

    .inputContainer {
        display: flex;
        gap: 20px;
    }
}

.KM_exportModal {
    min-width: 623px;

    .inputContainer {
        display: flex;
        gap: 20px;

    }

}

.KM_multipleSessionsModal {
    .container {
        max-width: 500px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .icon {
        cursor: pointer;
    }
}


.KM_hotkeysModal {
    padding: 40px;

    .KM_modalSection {
        display: flex;
        gap: 60px;

        h6 {
            text-align: left;
        }

        ul {
            padding: 0;
            list-style: none;

            li {
                display: flex;
                gap: 20px;
                margin-bottom: 10px;

                div.keys-wrapper {
                    display: flex;
                    gap: 8px;
                    align-items: center;

                    span.keys {
                        padding: 2px 8px;
                        background: var(--dark-100);
                        border-radius: 6px;
                    }
                }
            }

            li:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.KM_RetailTradingModal {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .broker {
        display: flex;
        gap: 20px;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 10px;
        border-radius: 12px;
        transition: background 0.3s;

        &:hover {
            background: var(--blue-100);
        }

        .broker-logo {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: var(--gray-100);
            display: flex;
            justify-content: center;
            align-items: center;

            &.loading {
                .fa-icon {
                    height: 40px;
                }

                animation: spin 1s linear infinite;

                @keyframes spin {
                    0% {
                        transform: rotate(0deg);
                    }

                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }

        .broker-name {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: var(--gray-900);
        }
    }
}