.KM_filter {
    width: 311px;
    padding-top: 18px;
    padding-bottom: 15px;
    .headerButton {
        fill: var(--dark-900)
    }

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
    }

    .search-wrapper {
        padding-left: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .select-all-text {
            color: var(--blue-600)
        }
    }

    .filter-wrapper {
        padding-left: 12px;
        max-height: 300px;
        overflow-y: scroll;
        gap: 35px;

        &::-webkit-scrollbar {
            width: 10px; // Adjust as needed
            border-radius: 6px;
        }

        &::-webkit-scrollbar-track {
            background: var(--blue-100); // Adjust as needed
        }

        // Style the scrollbar thumb
        &::-webkit-scrollbar-thumb {
            background: var(--blue-600); // Adjust as needed
        }
    }
}

.KM_filterButton {
    // display: flex;
    // align-items: center;
    margin-left: 9px;
    cursor: pointer;
    height: fit-content;

    .fa-icon {
        font-size: 13px;
        color: var(--dark-900);
    }
}