.main {
  background-color: var(--background-color);

  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 3em 0em calc(100% - 5em) 2em;
  grid-template-columns: 100%;
  grid-template-areas: 'header' 'banner' 'windows' 'footer';
}

#GLWMContainer {

  margin: 10px;
  width: calc(100vw - 20px);
}

.main.banner-active {
  grid-template-rows: 3em 2em calc(100% - 7em) 2em;
  grid-template-areas: 'header' 'banner' 'windows' 'footer';
}

/*
#GLWMContainer:has(.main.banner-active) {
  height: calc(100vh - 10px - 7em);
}
*/

#warning-banner {
  background: #ffd966;
  color: black;
}

* {
  user-select: none;
}

.jc-center {
  display: flex;
  justify-content: center;
}

.jc-sb {
  display: flex;
  justify-content: space-between;
}

.modalCloseButton {
  cursor: pointer;
  font-size: 17px;

}

.MuiAutocomplete-root.md {
  .autocomplete-custom {
    >div {
      padding: 0 12px !important;
    }
  }
}

.autocomplete-custom {

  >div {
    >fieldset {
      border: var(--dark-300) solid 1px;
      border-radius: 6px;

      &:hover {
        border-color: var(--blue-600);
      }
    }
  }

  &.readonly {

    input,
    fieldset {

      user-select: none;
      cursor: pointer;
    }
  }

  &.isOpen {
    svg {
      fill: var(--blue-600)
    }

    >div {
      >fieldset {
        border-radius: 6px 6px 0 0;
        border-bottom: none;
      }
    }
  }

  &.hasError:not(.isOpen) {
    >div {
      >fieldset {
        border-color: var(--error-red) !important;
      }
    }
  }
}