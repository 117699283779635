/* ------------------------------------------------------------ *\
    Form
\* ------------------------------------------------------------ */

form {
    .form__row {
        display: flex;
        margin: 0 -10px;

        &:not(:last-child) {
            margin-bottom: 25px;
        }

        @include breakpoint-down(mobile) {
            flex-direction: column;
        }
    }

    .form__col {
        flex-grow: 1;
        flex-basis: 0;
        padding: 0 10px;

        @include breakpoint-down(mobile) {
            &:not(:last-child) {
                margin-bottom: 25px;
            }
        }
    }

    .form__body {
        margin-bottom: 40px;

        @include breakpoint-down(mobile) {
            margin-bottom: 30px;
        }
    }
}


/* ------------------------------------------------------------ *\
    Formik
\* ------------------------------------------------------------ */

.formik-dropdown {
    &.isFocused {
        border-color: var(--blue-600);
    }

    &.isOpen {

        >fieldset {
            border-radius: 6px 6px 0 0;
            border-bottom: none;
        }
    }
}