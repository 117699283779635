.segmented-control {
    display: flex;
    position: relative;
    /* border: 1px solid #ccc; */
    border-radius: 15px;
    overflow: hidden;
    background-color: var(--blue-100);
    width: 100%;
    height: 26px;
}

.segmented-control-item {
    flex: 1;
    background: none;
    border: none;
    padding: 4px;
    font-size: 14px;
    cursor: pointer;
    z-index: 1;
    position: relative;
    transition: color 0.3s ease-out;
    color: var(--blue-600);
    text-align: center;
}

.segmented-control-item.active {
    color: white;
}

.segmented-control-slider {
    position: absolute;
    height: 100%;
    background-color: var(--blue-400);
    border-radius: 15px;
    transition: transform 0.3s ease-out;
    z-index: 0;
}