div >.closeButton{
    border:solid 1px #7a7777;
    background: #7a7777;
    color: white;
    border-radius: 2px;
    &:hover {
        color: white;
        background: #2b2929;
        border:solid 1px #7a7777;
    }
}