.KM_clear-input-icon {
    .KM_clear-input-icon-button {
        padding: 1px;
        transition: background-color 0.3s;
        width: 14px;
        height: 14px;

        &:hover {
            .clear-icon {
                color: var(--blue-600);
            }
        }

        .clear-icon {
            color: var(--dark-400);
            font-size: 12px;


        }
    }

    .isFocused.clear-icon {
        color: var(--blue-600);
    }
}