.KM_languageSelector {
    .title {
        color: var(--dark-600, #65666E);
        /* Tables / Small / Header */
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    .selectors {
        display: flex;
        gap: 10px;

        .flag-wrapper {
            display: flex;
            align-items: center;
            padding: 2px 7px 2px 3px;
            gap: 5px;

            &:not(.selected) {
                cursor: pointer;
            }

            .language {
                color: var(--Blue-900, #1D3097);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            &.selected {
                border: var(--blue-400) solid 1px;
                border-radius: 70px;
            }
        }
    }
}